.feedbackWrapper .rt-tr-group {
  margin: 1em 0 0 0;
  background: none;
  min-height: 140px;
}

.feedbackWrapper .rt-tr-group:last-child {
  margin: 1em 0 1em 0;
}

.feedbackWrapper .-odd {
  background-color: var(--white);
  border: solid 1px var(--grey-clouds);
  align-items: center;
}

.feedbackWrapper .-even {
  background-color: var(--white);
  border: solid 1px var(--grey-clouds);
  align-items: center;
}

.feedbackWrapper .rt-td:nth-child(1) {
  font-size: 16px;
  font-weight: 500;
}

.feedbackWrapper .rt-td:nth-child(3) {
  font-size: 16px;
  font-weight: 500;
}

.feedbackWrapper .rt-td:nth-child(4) {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}

.feedbackWrapper .rt-td {
  display: block !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

.reactTable .rt-table .rt-tbody {
  overflow-y: scroll !important;
  max-height: 300px;
}

.reactTable .rt-table .rt-tbody::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.reactTable .rt-table .rt-tbody::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.reactTable .rt-table .rt-tbody::-webkit-scrollbar-thumb {
  background: grey;
}

/* Handle on hover */
.reactTable .rt-table .rt-tbody::-webkit-scrollbar-thumb:hover {
  background: grey;
}

.reactTableLPO .rt-table .rt-tbody {
  overflow-y: scroll !important;
  max-height: 550px;
}

.reactTableLPO .rt-table .rt-tbody::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.reactTableLPO .rt-table .rt-tbody::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.reactTableLPO .rt-table .rt-tbody::-webkit-scrollbar-thumb {
  background: grey;
}

/* Handle on hover */
.reactTableLPO .rt-table .rt-tbody::-webkit-scrollbar-thumb:hover {
  background: grey;
}

.exceptionTable .rt-table .rt-tbody {
  overflow-y: scroll !important;
  max-height: 530px;
}

.exceptionTable .rt-table .rt-tbody::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.exceptionTable .rt-table .rt-tbody::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.exceptionTable .rt-table .rt-tbody::-webkit-scrollbar-thumb {
  background: grey;
}

/* Handle on hover */
.exceptionTable .rt-table .rt-tbody::-webkit-scrollbar-thumb:hover {
  background: grey;
}

.exceptionTableOthers .rt-table .rt-tbody {
  overflow-y: scroll !important;
  max-height: 450px;
}

.exceptionTableOthers .rt-table .rt-tbody::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.exceptionTableOthers .rt-table .rt-tbody::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.exceptionTableOthers .rt-table .rt-tbody::-webkit-scrollbar-thumb {
  background: grey;
}

/* Handle on hover */
.exceptionTableOthers .rt-table .rt-tbody::-webkit-scrollbar-thumb:hover {
  background: grey;
}

.historyTable .rt-table .rt-tbody {
  overflow-y: scroll !important;
  max-height: 230px;
}

.historyTable .rt-table .rt-tbody::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.historyTable .rt-table .rt-tbody::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.historyTable .rt-table .rt-tbody::-webkit-scrollbar-thumb {
  background: grey;
}

/* Handle on hover */
.historyTable .rt-table .rt-tbody::-webkit-scrollbar-thumb:hover {
  background: grey;
}

/*admin section scroll bar */

.adminTable .rt-table .rt-tbody {
  overflow-y: scroll !important;
  max-height: 450px;
}

.adminTable .rt-table .rt-tbody::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.adminTable .rt-table .rt-tbody::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.adminTable .rt-table .rt-tbody::-webkit-scrollbar-thumb {
  background: grey;
}

/* Handle on hover */
.adminTable .rt-table .rt-tbody::-webkit-scrollbar-thumb:hover {
  background: grey;
}
.adminTable .ReactTable .rt-thead.-filters {
  margin-top: 10px;
}

.adminTable .ReactTable .rt-noData {
top: 600% !important;
}

/*admin section scroll bar */

.adminTableDowntime .rt-table .rt-tbody {
  overflow-y: scroll !important;
  max-height: 650px;
}

.adminTableDowntime .rt-table .rt-tbody::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.adminTableDowntime .rt-table .rt-tbody::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.adminTableDowntime .rt-table .rt-tbody::-webkit-scrollbar-thumb {
  background: grey;
}

/* Handle on hover */
.adminTableDowntime .rt-table .rt-tbody::-webkit-scrollbar-thumb:hover {
  background: grey;
}
.adminTableDowntime .ReactTable .rt-thead.-filters {
  margin-top: 10px;
}

.adminTableDowntime .ReactTable .rt-noData {
top: 600% !important;
}



