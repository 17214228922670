:root {
    --xxsmallSize: 12px;
    --xsmallSize: 14px;
    --smallSize: 16px;
    --mediumSize: 18px;
    --largeSize: 20px;
    --middleLargeSize: 25px;
    --xlargeSize: 30px;
    --xxlargeSize: 80px;
}
