.circleImage {
    padding-left: 5px;
    height: 20px;
    width: 25px;
}

.circleImage.clickable {
    cursor: pointer;
}

.spinner {
    padding-left: 5px;
    height: 20px;
    width: 20px;
}
