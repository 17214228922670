@import url(./gothamFonts.css);


html {
  font-family: 'Gotham', sans-serif;
}

.infoIconCalculation {
  margin-right: 8px;
}

.greenDiff {
  color: var(--green-icing);
  font-weight: 300;
}

.redDiff {
  color: var(--ruby-slippers);
  font-weight: 300;
}

.clickableRoute {
  color: var(--blue-royal);
  cursor: pointer;
}
.addFlexProperty{
  display: flex;
}
.clickableRouteWithFlex{
  display: flex;
  color: var(--blue-royal);
  cursor: pointer;
}
.flexSpan{
  display: flex;
}
.centeredColAdmin{
    text-align: center;
}
#chartjs-tooltip {
  background-color: var(--white);
  border-radius: 8px;
  border: solid 1px var(--grey-clouds);
  box-sizing: initial;
  box-shadow: 0 2px 4px 0 rgba(51, 51, 51, 0.2);
  white-space: nowrap;
}

.chartjs-size-monitor {
  box-sizing: content-box;
}

.chartjs-render-monitor {
  box-sizing: content-box;
}


