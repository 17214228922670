

/* css style for Steps starts*/

.rs-steps {
 min-height: 30px;
}
.rs-steps-horizontal {
 display: -ms-flexbox;
 display: flex;
 -ms-flex-pack: justify;
 justify-content: space-between;
}

.rs-steps-item {
  padding-left: 40px;
  position: relative;
  overflow: hidden;
}

.rs-steps-horizontal .rs-steps-item {
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  min-height: 30px;
}

.rs-steps-small .rs-steps-item {
  padding-left: 34px;
}
.rs-steps-small.rs-steps-horizontal .rs-steps-item {
  min-height: 24px;
}
.rs-steps-horizontal .rs-steps-item:last-child {
    -ms-flex-positive: 0;
    flex-grow: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}
.rs-steps-horizontal .rs-steps-item:not(:first-child) {
    padding-left: 50px;
}
.rs-steps-small.rs-steps-horizontal .rs-steps-item:not(:first-child) {
    padding-left: 44px;
}

.rs-steps-item:not(:last-child) .rs-steps-item-tail,.rs-steps-item:not(:last-child) .rs-steps-item-title::after {
  position: absolute;
  border-color: #c5c6c7;
}
.rs-steps-item-status-finish .rs-steps-item-tail, .rs-steps-item-status-finish .rs-steps-item-title::after{
 border-color: #ccc !important;
}
.rs-steps-item-icon-wrapper {
  display: block;
  width: 30px;
  height: 30px;
  text-align: center;
  font-size: 25px;
  line-height: 1.75;
  color: #8e8e93;
  position: absolute;
  top: -5px;
  left: 0;
}

.rs-steps-small .rs-steps-item-icon-wrapper {
  width: 24px;
  height: 24px;
  font-size: 14px;
  line-height: 1.57142857;
}
.rs-steps-horizontal .rs-steps-item:not(:first-child) .rs-steps-item-icon-wrapper {
    left: 10px;
}
.rs-steps-item-icon-wrapper > .rs-steps-item-icon {
  width: 100%;
  display: block;
  text-align: center;
}
.rs-steps-item-content {
  color: #8e8e93;
  display: inline-block;
  position: relative;
  width: 100%;
}
.rs-steps-horizontal .rs-steps-item-content {
  height: 30px;
}
.rs-steps-item-title {
  position: relative;
  font-size: 16px;
  line-height: 1.875;
  min-height: 16px;
  padding-right: 10px;
  display: inline-block;
}
.rs-steps-item-title:empty {
  padding-right: 0;
}
.rs-steps-small .rs-steps-item-title {
  font-size: 14px;
  line-height: 1.71428571;
}
.rs-steps-item-tail, .rs-steps-item-title::after {
  position: absolute;
  border-color: #c5c6c7;
}
.rs-steps-item-status-finish .rs-steps-item-icon-wrapper, .rs-steps-item-status-process .rs-steps-item-icon-wrapper {
  color: #ccc;
}
.rs-steps-horizontal  .rs-steps-item:not(:last-child)  .rs-steps-item-title::after {
  content: '';
  left: 100%;
  width: 9999px;
  border-top-width: 1px;
  border-top-style: solid;
  top: 8px;
}
.rs-steps-item-description {
    margin-top: 12px;
    position: relative;
    right: 30px;
}
.rs-steps-small .rs-steps-item-description {
  margin-top: 12px;
  font-size: 12px;
  line-height: 1.66666667;
  position: relative;
  right: 30px;
}







/* css style for Steps ends*/