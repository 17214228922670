:root {
    --black: #000000;
    --yellow: #faab18;

    --dk-opaque-white: rgba(255, 255, 255, 0.48);
    --opaque-white: rgba(255, 255, 255, 0.15);
    --white: #ffffff;

    --dk-green: #769231;
    --green: #b2cc34;

    --dkOrange: #de741f;
    --orange: #e5601f;

    --darker-shadow-grey: rgba(0, 0, 0, 0.3);
    --disabled-blue-grey: rgba(0, 157, 217, 0.25);
    --dk-grey: #333333;
    --grey-border-and-bg: #ededee;
    --grey-input-border: #dbdbdb;
    --grey-nav: #6b6d6f;
    --grey-scorecard: #dbdcdd;
    --grey-text: #8c8f93;
    --grey: #a7a7a7;
    --lt-grey: #f5f6fa;
    --shadow-grey: rgba(0, 0, 0, 0.1);

    --alert-grey: #8c99ae;
    --arrow-grey: #d8d8d8;
    --line-grey: #ededee;
    --lt-blue-grey: #b3c0d3;
    --md-blue-grey: #6b6d6f;
    --read-grey: #6b6d6f;
    --shadow-grey: #f4f4f4;
    --unread-grey: #606b7b;

    --alert-maroon: #97002e;
    --red: #d0021b;

    --blue-nav-bg: rgba(0, 157, 217, 0.05);
    --cerulean: #009dd9;
    --md-blue: #0066b2;

    --purple: #a84298;

    --hairline-border: rgba(237, 237, 238, 0.16);
    --new-task-teal: #02b0c2;
    --task-border: #dcdcdd;
    --tool-tip-box-shadow: 0 0 4px 0;

    /* New Colors, Use These! */
    --blue-baby: #b2d1e7;
    --blue-deepSea: #06468e;
    --blue-royal: #0066b2;
    --blue-sky: #80ceec;
    --blue-smurf: #009dd9;
    --blue-twilight: #0b2d71;
    --green-icing: #00cc92;
    --grey-charcoal: #333333;
    --grey-clouds: #dbdcdd;
    --grey-linen: #fcfcfc;
    --grey-metal: #8c8f93;
    --grey-soot: #aaadb3;
    --grey-wash: #fafafa;
    --grey-water: #f5f5f5;
    --purple-candy: #a84298;
    --purple-grape: #66285d;
    --red-apple: #e21836;
    --ruby-slippers: #ee4266;
    --white: #ffffff;
}
