@import url(assets/css/gothamFonts.css);

html {
    box-sizing: border-box;
    font-family: 'Gotham', sans-serif;
}

/* Webkit browsers like Safari and Chrome */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.slide-pane {
    box-shadow: none !important;
}

.slide-pane__content {
    padding: 0 !important;
}

.slide-pane__header {
    display: none !important;
}

.ReactTable {
    border: none !important;
    margin-bottom: 40px;
}

.rt-td {
    text-overflow: ellipsis !important;
}

.targetClass {
    text-overflow: clip !important;
}

.rt-th,
.rt-tr-group,
.rt-thead {
    border: none !important;
    box-shadow: none !important;
    text-align: left;
    transition: none !important;
}

.downtimeTable .rt-th {
    text-align: center;
}

.downtimeTable .rt-tr-group {
    text-align: center;
}

.summaryTable .rt-th {
    text-align: center;
}

.summaryTable .rt-tr-group {
    text-align: center;
}

.summaryTable .rt-thead {
    text-align: center;
}

.historyTable .rt-th {
    text-align: center;
}

.historyTable .rt-tr-group {
    text-align: center;
}

.historyTable .rt-thead {
    text-align: center;
}

.historyTable .rt-thead {
    background-color: var(--grey-water);
    margin-bottom: -10px;
    padding-bottom: 10px;
    padding-top: 10px;
}

.historyTable .rt-tbody {
    border-top: none;
}

.historyTable .-odd {
    background-color: var(--white);
}

.historyTable .-even {
    background-color: var(--grey-water);
}

.columnHeader {
    align-content: flex-start;
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-bottom: -4px;
}

.centeredColumnHeader {
    align-content: flex-start;
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-bottom: -4px;
    justify-content: center;
}

.centeredColumnHidden {
    align-content: flex-start;
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-bottom: -4px;
    justify-content: center;
}

.centeredColumnHidden.-sort-desc::after {
    content: none;
    margin-left: 4px;
}

.centeredColumnHidden.-sort-asc::after {
    content: none;
    margin-left: 4px;
}

.-sort-desc::after {
    content: url(assets/img/up-blue.svg);
    margin-left: 4px;
}

.-sort-asc::after {
    content: url(assets/img/down-blue.svg);
    margin-left: 4px;
}

.rt-th {
    color: var(--grey-charcoal);
    font-size: 16px;
    font-weight: 500;
    padding: 0 20px !important;
}

.rt-tbody {
    border-top: solid 1px var(--grey-soot);
    margin-top: 10px;
}

.rt-td {
    border-right: none !important;
    padding: 30px 20px !important;
}

.tableImg {
    height: 15px;
    position: absolute;
}

.rt-tr-group {
    height: 80px;
}

.-odd {
    background-color: var(--grey-water);
}

.rt-email {
    color: var(--blue-royal);
    font-family: 'Gotham', sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: 1.38;
    text-decoration: none !important;
}

.rt-tbody {
    overflow: hidden !important;
}

.react-datepicker-popper {
    z-index: 100 !important;
}

@import url(assets/css/feedbackTableStyle.css);

::-webkit-input-placeholder {
    font-style: italic;
    color: var(--grey-soot);
}

img,
::after,
::before {
    box-sizing: content-box;
}

a,
::after,
::before {
    box-sizing: initial;
}

@media only screen and (max-width: 1400px) {
    .rt-th {
        font-size: 11px;
    }
}

@media only screen and (max-width: 1320px) {

    .ReactTable .rt-th,
    .ReactTable .rt-td {
        font-size: 14px;
        white-space: unset !important;
        padding: 10px !important;
    }

    .rt-tr-group {
        height: 60px !important;
    }
}

@media only screen and (max-width: 1320px) {
    .break-word {
        word-break: break-word;
    }
}