.modalOverlay {
    background-color: rgba(255, 255, 255, 0.75);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100;
}

.modalContainer {
    align-items: center;
    background-color: transparent;
    border-radius: 8px;
    color: var(--grey-charcoal);
    display: flex;
    flex-direction: column;
    height: auto;
    left: 20rem;
    left: 0;
    margin-top: 20px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 29px;
    position: absolute;
    top: 35%;
    width: 100%;
}

.modalCompletionCheckedIcon {
    height: auto;
    margin-top: 15px;
    width: 60px;
}

.modalTitle {
    color: black;
    display: inline-block;
    font-family: 'Gotham', sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 500;
    letter-spacing: normal;
    line-height: normal;
    margin-top: 20px;
    text-align: center;
}

.modalBody {
    margin-top: 15px;
    max-width: 80%;
    text-align: center;
}
.buttonsArea {
    align-items: baseline;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 20px;
    margin-top: 30px;
}

.saveBtn {
    background-color: var(--blue-royal);
    border: none;
    color: var(--white);
    cursor: pointer;
    font-size: 16px;
    outline: none;
    padding: 8px 20px;
}


.cancelBtn {
    background-color: transparent;
    border: none;
    color: var(--blue-royal);
    cursor: pointer;
    font-size: 16px;
    margin-left: 20px;
    outline: none;
    padding: 8px 20px;
}
